import React from "react"
import styles from "./article.module.scss"
import classNames from "classnames/bind"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import LayoutContainer from "../components/Common/LayoutContainer"
import MarkdownHeader from "../components/MarkdownHeader"
import ArticleSidebar from "../components/Article/ArticleSidebar"
import ContentDataList from "../components/Article/ContentDataList"
import ArticleMetaInfo from "../components/Article/ArticleMetaInfo"

const cx = classNames.bind(styles)

export const pageQuery = graphql`
  query ArticleQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      lists {
        restaurants
      }
      updatedAt(formatString: "YYYY년 MM월")
      destination {
        slug
        title
        article {
          title
          slug
          toc
        }
      }
      headerImage {
        fluid {
          src
        }
      }
    }
    contentData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/contentData//" } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
          fileAbsolutePath
        }
      }
    }
  }
`

const Article = ({ data, location }) => {
  const {
    destination,
    title,
    lists,
    content,
    updatedAt,
    headerImage,
  } = data.contentfulArticle
  return (
    <Layout location={location} destination={destination.title}>
      <SEO title={title} />

      <div className={cx("flexWrapper")}>
        <div className={cx("flexAuto")}>
          <LayoutContainer>
            <div className="articleContainer">
              <article className={cx("flexArticle")}>
                <MarkdownHeader title={title} />
                {headerImage && (
                  <img
                    src={headerImage.fluid.src}
                    width="100%"
                    className={cx("headerImg")}
                    alt="Header"
                    loading="lazy"
                  />
                )}
                <div className="articleContent">
                  <div
                    className="markdown"
                    dangerouslySetInnerHTML={{
                      __html: content.childMarkdownRemark.html,
                    }}
                  />
                </div>

                <ContentDataList lists={lists} contentData={data.contentData} />
                <ArticleMetaInfo updatedAt={updatedAt} />
              </article>
              <ArticleSidebar location={location} destination={destination} />
            </div>
          </LayoutContainer>

          {/* <NavigationFooter location={location} next={"next"} prev={"prev"} /> */}
        </div>
      </div>
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Article
