import React, { Component } from "react"
import styles from "./Sidebar.module.scss"
import classNames from "classnames/bind"
import Section from "./Section"

const cx = classNames.bind(styles)

export default class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSection: null,
    }
  }

  _isActive = (location, article, destSlug) =>
    location.pathname === `/dest/${destSlug}/a/${article.slug}`

  // _toggleSection = section => {
  //   this.setState(state => ({
  //     activeSection: state.activeSection === section ? null : section,
  //   }))
  // }

  render() {
    const { closeParentMenu, location, destination } = this.props
    // const { activeSection } = this.state

    return (
      <nav className={cx("flexWrapper", "sidebar")}>
        {destination.article
          .sort((a, b) => {
            if (a.toc < b.toc) return -1
            if (a.toc > b.toc) return 1
            return 0
          })
          .map((article, index) => {
            return (
              <Section
                key={index}
                onLinkClick={closeParentMenu}
                section={article}
                dest={destination.slug}
                isActive={location.pathname.includes(
                  `/dest/${destination.slug}/a/${article.slug}`
                )}
                // onSectionTitleClick={() => this._toggleSection(section)}
                // createLink={createLink}
                // location={location}
              />
            )
          })}
      </nav>
    )
  }
}
