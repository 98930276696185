import React, { Component } from "react"
import styles from "./Section.module.scss"
import classNames from "classnames/bind"
// import ChevronSvg from "../../icons/ChevronSvg"
import { Link } from "gatsby"

const cx = classNames.bind(styles)

export default class Section extends Component {
  state = { uid: ("" + Math.random()).replace(/\D/g, "") }

  render() {
    const { section, dest, isActive } = this.props
    // const uid = "section_" + this.state.uid

    return (
      <Link
        to={`/dest/${dest}/a/${section.slug}`}
        className={cx("linkItem", { listItemHighlight: isActive })}
      >
        {isActive && <span className={cx("activeItemBefore")} />}
        {section.title}
      </Link>
      // <div>
      //   <button
      //     aria-expanded={true}
      //     aria-controls={uid}
      //     className={cx("btnSidebar")}
      //     // onClick={onSectionTitleClick}
      //   >
      //     <div
      //       className={cx(
      //         "sidebarTitle",
      //         isActive ? "textColor" : "subtleColor"
      //       )}
      //     >
      //       section title
      //       <ChevronSvg
      //         cssProps={{
      //           marginLeft: "7px",
      //           transform: isActive ? "rotateX(180deg)" : "rotateX(0deg",
      //           transition: "transform 0.2s ease",
      //           [`@media (maxWidth: 599px)`]: { display: "none" },
      //         }}
      //       />
      //     </div>
      //   </button>

      //   <ul
      //     id={uid}
      //     className={cx("listContainer", isActive ? "block" : "none")}
      //   >
      //     <li className={cx("list_item")}>
      //       <Link to={`/dest/${dest}/a/${section.slug}`}>{section.title}</Link>
      //     </li>
      //   </ul>
      // </div>
    )
  }
}
