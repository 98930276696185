import React from "react"
import styles from "./ContentDataList.module.scss"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const ContentDataEntry = ({ node }) => {
  return (
    <div className={cx("dataEntry")}>
      <h3>{node.frontmatter.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: node.html }} />
    </div>
  )
}

const ContentDataList = ({ lists, contentData }) => {
  return (
    <div className={cx("contentDataList")}>
      {lists && (
        <div className={cx("inner")}>
          {contentData.edges
            .filter(({ node }) =>
              lists["restaurants"].includes(node.frontmatter.title)
            )
            .map(({ node }) => (
              <ContentDataEntry key={node.frontmatter.title} node={node} />
            ))}
        </div>
      )}
    </div>
  )
}

export default ContentDataList
