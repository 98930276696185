import React from "react"
import styles from "./MarkdownHeader.module.scss"

const MarkdownHeader = ({ title }) => {
  return (
    <header className={styles.flexWrapper}>
      <h1 className={styles.header}>{title}</h1>
    </header>
  )
}

export default MarkdownHeader
