import React from "react"
import styles from "./ArticleMetaInfo.module.scss"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const ArticleMetaInfo = ({ updatedAt }) => {
  return (
    <div className={cx("metaInfo")}>
      <span>업데이트: {updatedAt}</span>
      {/* <span>업데이트: {updatedAt}</span> */}
    </div>
  )
}

export default ArticleMetaInfo
