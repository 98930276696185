import React, { useState } from "react"
import classNames from "classnames/bind"
import styles from "./ArticleSidebar.module.scss"
import Sidebar from "../Sidebar/Sidebar"
import ChevronSvg from "../../icons/ChevronSvg"

const cx = classNames.bind(styles)

const ArticleSidebar = ({ location, destination }) => {
  const [open, setOpen] = useState(false)

  const _opennavMenu = () => {
    setOpen(!open)
  }

  const _closeNavMenu = () => {
    setOpen(false)
  }
  // const { open } = this.state
  const menuOpacity = open ? 1 : 0
  const iconOffset = open ? 8 : -4
  const menuOffset = open ? 0 : 40

  return (
    <div className={cx("articleSidebar")}>
      <div
        className={cx("wrapper", open ? "open" : "close")}
        style={{ opacity: menuOpacity }}
      >
        <div
          className={cx("sidebarWrapper")}
          style={{ transform: `translate(0px, ${menuOffset}px)` }}
        >
          <Sidebar
            closeParentMenu={_closeNavMenu}
            destination={destination}
            location={location}
          />
        </div>
      </div>

      <div
        className={cx("toggler")}
        onClick={_opennavMenu}
        role="button"
        tabIndex={0}
      >
        <div className={cx("container")}>
          <div className={cx("toggle_btn_container")}>
            <div className={cx("toggle_btn")}>
              <ChevronSvg
                size={15}
                cssProps={{
                  transform: `translate(2px, ${iconOffset}px) rotate(180deg)`,
                  transition: `transform 0.2s ease`,
                }}
              />
              <ChevronSvg
                size={15}
                cssProps={{
                  transform: `translate(2px, ${0 - iconOffset}px)`,
                  transition: "transform 0.2s ease",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleSidebar
